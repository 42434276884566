$theme-colors: (
  'primary': #ee7268,
  'dark': #262525,
  'danger': #ee7268,
  'success': #34c588,
  'info': #4a90e3,
);
$font-family-sans-serif: 'Titillium Web', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

@import 'node_modules/bootstrap/scss/bootstrap.scss';

body {
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none; /* Webkit */
  // -moz-user-select: none; /* Firefox */
  // -ms-user-select: none; /* IE 10  */

  // /* Currently not supported in Opera but will be soon */
  // -o-user-select: none;
  // user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-y {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.scroll-x {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.profile-photo {
  border-width: 0px;
}

.profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  background-color: rgba(0, 0, 0, 0.4);
}

.profile-photo:hover {
  border-width: 2px;
}

.profile-photo:hover .profile-overlay {
  opacity: 1;
}

.swal2-container {
  // z-index: 1020!important;
}

.text-underline-hover:hover {
  text-decoration: underline !important;
}

.support-link {
  color: #34c588;
  font-size: 1.4em;
  text-decoration: none;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.support-link:hover {
  color: #34c588;
}

.styled-link {
  color: #ee7268;
  text-decoration: none;
  cursor: pointer;
}

.styled-link:hover {
  color: #ee7268;
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.welcome-text {
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 30px 0;
}

.table-hover tbody tr:hover {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.075);
}

.bg-opaque {
  background-color: rgba(255, 255, 255, 0.075);
}

.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.w-inherit {
  width: inherit;
}

.outline-0 {
  outline: none !important;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.pointer-events-none {
  pointer-events: none;
}

.pb-40vh {
  padding-bottom: 40vh;
}

.hill-drop-shadow {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.6));
}

.z-1 {
  z-index: 1;
}

.toc {
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
}

.card-design {
  position: relative;
  overflow: hidden;
  background-color: #262525;
}

.card-design::before {
  content: '';
  transform: translate3d(-65px, -20px, 0) scaleX(-1);
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 200px 0;
  background-image: url(./assets/CoinLogoWithText2.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in;
  opacity: 0.4;
}

.card-design:hover::before {
  background-color: rgba(200, 200, 200, 0.4);
}

.card-design-deactivated,
.card-design-inactive {
  background-color: rgba(200, 200, 200, 0.8);
}

.hover-shadow {
  box-shadow: rgba(0, 0, 0, 0.8) 0.5em 0.5em 1em;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.hover-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0.2em 0.2em 0.4em;
  transform: translate3d(0, -4px, -4px);
}

.line-height-1 {
  line-height: 1em !important;
}

.text-shadow {
  text-shadow: 4px 4px rgba(0, 0, 0, 1);
}

.underline {
  border-bottom: inherit;
}

.underline:after {
  border-bottom: 2px solid theme-colors('primary');
}

.bg-gray {
  background-color: #bbbbbb;
}

.bg-blue {
  background-color: #549be6;
  color: #fff;
}

.bg-blue-light {
  background-color: #d3e5f8;
}

.particles {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in, visibility 0s linear 0.3s;
}

.particles-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in, visibility 0s linear;
}

.swal2-icon.swal2-info {
  color: #09baa6;
  border-color: #0ad3bc;
}

.swal-wide {
  width: 42em !important;
}

.btn-pill {
  border-radius: 1em;
}

.accordion {
  img {
    max-width: 100%;
  }
}

@media all and (max-height: 400px) {
  .footer {
    display: none;
  }
}
@media all and (max-width: 600px) {
  .font-small-sm {
    font-size: 0.7em;
  }
  .rounded-0-sm {
    border-radius: 0 !important;
  }
}
@media all and (min-width: 767px) {
  .border-left-md {
    border-left: 1px solid;
  }
  .font-small-md {
    font-size: 0.85em;
  }
  .toc-md {
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
  }
  .quadkey-map {
    max-width: 140px;
  }
}

.quadkey-map {
  width: 100%;
}

.skeleton {
  animation: skeleton 1.5s ease-in-out infinite;
}

@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.opaque {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.react-html5-camera-photo {
  overflow: hidden;
}

button {
  outline: none !important;
}

span .profile-badge {
  height: 32px;
  border-radius: 16px;
  color: #ffffff;
  top: inherit;
  bottom: -12.5px;
  right: 12.5px;
}
legend {
  width: initial;
}
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected,
.MuiInputBase-root input:-webkit-autofill,
.MuiInputBase-root input:-webkit-autofill:hover,
.MuiInputBase-root input:-webkit-autofill:focus,
.MuiInputBase-root input:-webkit-autofill:active,
.MuiInputBase-root input:-internal-autofill-selected {
  box-shadow: 0 0 0 1000px #424242 inset !important;
  -webkit-box-shadow: 0 0 0 1000px #424242 inset !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.crosshair-v {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1px;
  border-left: 1px dashed #eee;
  opacity: 0.2;
  pointer-events: none;
}
.crosshair-h {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  border-top: 1px dashed #eee;
  opacity: 0.2;
  pointer-events: none;
}
a:hover {
  color: inherit;
}
.opacity-half {
  opacity: 0.5;
}

.hover-underline {
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
}

.bg-bkgd {
  background-color: #424242 !important;
}

.bg-white {
  background-color: #fff !important;
}

.overflow-initial {
  overflow-x: initial !important;
  overflow-y: initial !important;
}
.winner-badge-style {
  height: 30px !important;
  width: 30px !important;
  border-radius: 15px !important;
}

particle {
  border-radius: 50%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  opacity: 0;
}
